import React from 'reactn';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Calendar from './components/Calendar';
import ConductorSearch from './components/ConductorSearch';
import Contact from './components/Contact';
import DavidKirven from './components/DavidKirven';
import DayOfBrass2025 from './components/DayOfBrass2025';
import Donate from './components/Donate';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import Photos from './components/Photos';
import Privacy from './components/Privacy';
import ReactGA from "react-ga4";
import Youth from './components/Youth';
import './styles/base.scss';

// site router
const myRouter = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/calendar",
		element: <Calendar />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/conductorsearch",
		element: <ConductorSearch />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/contact",
		element: <Contact />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/dayofbrass2025",
		element: <DayOfBrass2025 />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/DayOfBrass",
		element: <DayOfBrass2025 />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/donate",
		element: <Donate />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/newdirector",
		element: <DavidKirven />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/photos",
		element: <Photos />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/privacy",
		element: <Privacy />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/youth",
		element: <Youth />,
		errorElement: <ErrorPage />,
	},
]);

// global SendAnalytics function
const SendAnalytics = ()=> {
	ReactGA.send({
		hitType: "pageview",
		page: window.location.pathname,
	});
}

  // create and render the root node
  ReactGA.initialize("G-WNEJ9BJH65");
  ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<RouterProvider router={myRouter} />
	</React.StrictMode>
);
SendAnalytics();
