import React from 'reactn';
import DayOfBrass2025View from './DayOfBrass2025-view';
import { useNavigate } from 'react-router';

const DayOfBrass2025 = props => {
	const navigate = useNavigate();

	function click() {
		navigate("/DayOfBrass");
	}

	return (
		<DayOfBrass2025View
			click = {click}
		/>
	)
}

export default DayOfBrass2025;
