import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// images
import DavidKirven from "./images/davidKirven.png";
import FairfaxCommission from "./images/city-of-ffx-commisson-of-the-arts.png";
import DennisEdelbrock from "./images/dennisEdelbrock.png";
import JohnCradler from "./images/johnCradler.jpg"
import JosephCradler from "./images/josephCradler.png";
import Logo from "./images/DayOfBrass2025.png";
import ShannonGunn from "./images/shannonGunn.png";

// styles
import styles from './DayOfBrass2025.module.scss';

export default function DayOfBrass2025View(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Day of Brass III</title>
					<meta name="description" property='og:description' content='Learn about our third annual Day of Brass educational and performance event'/>
					<meta property='og:url' content='https://cathedralbrass.org/DayOfBrass'/>
					<meta property='og:type' content='article'/>
					<meta name="viewport" content="width=device-width, initial-scale=1" />
				</Helmet>
				<Header />
				<InteriorPage>
					<div className={styles.page}>
						<div className={styles.pageMain}>
							<div className={styles.pageTop}>
								<h2>The City of Fairfax Commission on the Arts<br/>and The Cathedral Brass present</h2>
								<h1>Day of Brass III</h1>
							</div>
							<div>
								<div className={styles.section}>
									<h2>About the Event</h2>
									<p>The Cathedral Brass is pleased to present our third annual DAY OF BRASS! This event is the representation of our efforts to create an engaging and enriching educational opportunity for young brass players in Washington Metro area. The purpose is to expose and perform traditional brass choir repertoire and work with highly accomplished and successful brass artists. We have the good fortune of having a very strong slate of clinicians to work with our participants, and it is sure to be an experience to remember for years to come!</p>
								</div>
								<div className={styles.section}>
									<h2>Guest Clinicians</h2>
									<div className={styles.bios}>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={DennisEdelbrock} alt="" />
											</div>
											<div className={styles.name}>
												Dr. Dennis Edelbrock
											</div>
											<div className={styles.cred}>
												George Mason University
											</div>
											<div className={styles.instrument}>
												Trumpet
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={JosephCradler} alt="" />
											</div>
											<div className={styles.name}>
												Joseph Cradler
											</div>
											<div className={styles.cred}>
												United States Marine Band
											</div>
											<div className={styles.instrument}>
												Horn
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={ShannonGunn} alt="" />
											</div>
											<div className={styles.name}>
												Shannon Gunn
											</div>
											<div className={styles.cred}>
												Virginia Commonwealth University
											</div>
											<div className={styles.instrument}>
												Trombone
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={JohnCradler} alt="" />
											</div>
											<div className={styles.name}>
												John Cradler
											</div>
											<div className={styles.cred}>
												United States Marine Band (Ret.)
											</div>
											<div className={styles.instrument}>
												Tuba
											</div>
										</div>
										<div className={styles.bio}>
											<div className={styles.photo}>
												<img src={DavidKirven} alt="" />
											</div>
											<div className={styles.name}>
												David Kirven
											</div>
											<div className={styles.cred}>
												United States Army Band
											</div>
											<div className={styles.instrument}>
												Conducting
											</div>
										</div>
									</div>
								</div>
								<div className={styles.section}>
									<h2>Tentative Schedule</h2>
									<table>
										<tbody>
										<tr>
												<td>9:30 a.m.</td>
												<td>to</td>
												<td>10:00 a.m.</td>
												<td>Registration</td>
											</tr>
											<tr>
												<td>10:00 a.m.</td>
												<td>to</td>
												<td>11:15 a.m.</td>
												<td>Mass brass sight reading/rehearsal (John Cradler)</td>
											</tr>
											<tr>
												<td>11:15 a.m.</td>
												<td>to</td>
												<td>11:30 a.m.</td>
												<td>Break</td>
											</tr>
											<tr>
												<td>11:30 a.m.</td>
												<td>to</td>
												<td>12:15 p.m.</td>
												<td>Master Class for all students (David Kirven)</td>
											</tr>
											<tr>
												<td>12:15 p.m.</td>
												<td>to</td>
												<td>12:45 p.m.</td>
												<td>Lunch Break (see &ldquo;Lunch&rdquo; below)</td>
											</tr>
											<tr>
												<td>12:45 p.m.</td>
												<td>to</td>
												<td>1:45 p.m.</td>
												<td>Instrument Master Classes (Denny Edelbrock, Joe Cradler, John Cradler, Shannon Gunn)</td>
											</tr>
											<tr>
												<td>1:45 p.m.</td>
												<td>to</td>
												<td>2:15 p.m.</td>
												<td>Panel Discussion (all students)</td>
											</tr>
											<tr>
												<td>2:15 p.m.</td>
												<td>to</td>
												<td>2:30 p.m.</td>
												<td>Break</td>
											</tr>
											<tr>
												<td>2:30 p.m.</td>
												<td>to</td>
												<td>3:00 p.m.</td>
												<td>Mass brass ensemble warm-up/run-through</td>
											</tr>
											<tr>
												<td>3:00 p.m.</td>
												<td>to</td>
												<td>3:30 p.m.</td>
												<td>Youth Ensemble run-through</td>
											</tr>
											<tr>
												<td>4:00 p.m.</td>
												<td></td>
												<td></td>
												<td>Closing concert featuring the Cathedral Brass Youth Ensemble, the Cathedral Brass with guest clinicians, and the mass brass ensemble</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className={styles.section}>
									<h2>Registration</h2>
									<p>Registration is FREE for all participating students! In order to know the number of students participating and our instrumentation, we ask that you <a href="https://forms.gle/juYXvcqVe8K3UnqN9" target="_blank" rel="noreferrer">complete this registration form.</a></p>
								</div>
								<div className={styles.section}>
									<h2>Preparing for Day of Brass</h2>
									<p>Registrants should download, print, and familiarize themselves with the Day of Brass repertoire prior to the event. Music will be made available on this site on April 1st, 2025.</p>
								</div>
								<div className={styles.section}>
									<h2>Lunch</h2>
									<p>Due to the various dietary restrictions that may be present, we are asking that students pack their own lunch for this event. This ensures that every student has a meal and that every student is responsible for their own allergies. If your student does have allergies, please be sure to have any required medical assistance devices (epi-pens, etc.) available for use. We will provide bottled water for all participating students.</p>
								</div>
								<div className={styles.section}>
									<h2>Dress for the concert</h2>
									<p>Students attending the workshop can dress comfortably for the day. We do ask that students be in business casual attire for the performance. If a student elects to be in business casual dress for the entire day, that is perfectly acceptable.</p>
								</div>
								<div className={styles.section}>
									<h2>Other things to consider</h2>
									<p>When a student registers, he/she will be given access to the selections that Mr. Cradler has recommended for performance. It is expected that the students will come to Day of Brass with the music in a state of preparation conducive to rehearsal. Students should bring all other items that are required for the day (mutes, valve oil, etc.).</p>
								</div>
							</div>
						</div>
						<div className={styles.pageSide}>
							<img src={Logo} alt="" />
							<div className={styles.ffxLogo}>
								<img src={FairfaxCommission} alt="City of Fairfax Commission on the Arts" />
							</div>
							<h3>Date & Time</h3>
							<p>April 26th, 2025<br/>10:00 a.m.&nbsp;&ndash;&nbsp;3:30 p.m.<br/>
							Concert at 4:00 p.m.</p>
							<h3>Location</h3>
							<p><a href="https://www.google.com/maps/place/3740+Blenheim%20Blvd,%20Fairfax,%20VA%2022030,%20USA" target="_blank" rel="noreferrer">Stacy C. Sherwood Community Center<br/>
							Fairfax, Virginia</a></p>
							<h3>Cost</h3>
							<p>Free for all students grades 7&nbsp;&ndash;&nbsp;12</p>
							<h3>Registration</h3>
							<p><a href="https://forms.gle/juYXvcqVe8K3UnqN9" target="_blank" rel="noreferrer">Please complete this form</a></p>
						</div>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
