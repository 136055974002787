import React from 'reactn';
import { Helmet } from "react-helmet";

// components
import Footer from '../common/Footer';
import Header from '../common/Header';
import InteriorPage from '../common/InteriorPage';

// styles
import styles from './Youth.module.scss';

export default function Calendar(props) {
	return (
		<>
			<div className="appContent">
				<Helmet>
					<meta charSet="utf-8" />
					<title>The Cathedral Brass - Youth Ensemble</title>
					<meta name="description" property='og:description' content='The Cathedral Brass Youth Ensemble offers education and performance opportunities to student musicians in the DMV region.'/>
					<meta property='og:url' content='https://cathedralbrass.org/youth'/>
					<meta property='og:type' content='article'/>
				</Helmet>
				<Header />
				<InteriorPage title="Youth Ensemble">
					<div className={styles.youthPage}>
						<p className="intro">The Cathedral Brass Youth Ensemble is a program for
							middle school and high school-aged brass players. The Youth Ensemble 
							provides students with an introduction to the performance of brass 
							literature drawn from many eras and genres, emphasizing basic ensemble 
							skills, musicianship, and teamwork.</p>
						<h2>Leadership</h2>
						<p>The Youth Ensemble is coached and conducted by Master Gunnery Sgt. (ret.) 
							John Cradler, former Principal Tuba player with the United States Marine 
							Band (&ldquo;The President&rsquo;s Own&rdquo;).</p>
						<h2>Schedule and Cost</h2>
						<p>The Cathedral Brass Youth Ensemble rehearses once a month Vienna
							Presbyterian Church, Sundays from 3:30 p.m. - 5:00 p.m., with final 
							concerts in December and May. Tuition is $25 per semester (September - 
							December, January - May).</p>
						<h3>Semester 1</h3>
						<table>
							<thead>
								<tr>
									<th>
										Date
									</th>
									<th>
										Time
									</th>
									<th>
										Activity
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										22 Sep 2024
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										20 Oct 2024
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										17 Nov 2024
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										8 Dec 2024
									</td>
									<td>
										2:00 p.m. - 3:30 p.m.
									</td>
									<td>
										Dress Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										8 Dec 2024
									</td>
									<td>
										4:00 p.m. - 5:30 p.m.
									</td>
									<td>
										<strong>Holiday Concert</strong> (with Cathedral Brass)
									</td>
								</tr>
							</tbody>
						</table>
						<h3>Semester 2</h3>
						<table>
							<thead>
								<tr>
									<th>
										Date
									</th>
									<th>
										Time
									</th>
									<th>
										Activity
									</th>
								</tr>
							</thead>
							<tbody>

								<tr>
									<td>
										12 Jan 2025
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										16 Feb 2025
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										23 Mar 2025
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										6 Apr 2025
									</td>
									<td>
										3:30 p.m. - 5:00 p.m.
									</td>
									<td>
										Dress Rehearsal
									</td>
								</tr>
								<tr>
									<td>
										26 Apr 2025
									</td>
									<td>
										TBD
									</td>
									<td>
										<strong>Day of Brass III Concert</strong> (with Cathedral Brass)
									</td>
								</tr>
							</tbody>
						</table>
						<h2>Interested in playing?</h2>
						<p>If you&rsquo;d like to join the Cathedral Brass Youth Ensemble, please 
							email <a href="mailto:youthbrass@cathedralbrass.org">youthbrass@cathedralbrass.org</a>, 
							or call Mark Herzing at 703-851-4709.</p>
					</div>
				</InteriorPage>
			</div>
			<Footer />
		</>
	)
}
