import React from 'reactn';
import DayOfBrass2025View from './DayOfBrass2025-view';

const DayOfBrass2025 = props => {
	return (
		<DayOfBrass2025View />
	)
}

export default DayOfBrass2025;
