import React from 'reactn';
import styles from './DayOfBrass2025.module.scss';

export default function DayOfBrass2025View(props) {
	return (
		<div className={styles.feature} onClick={props.click}>
			<p>To learn more and register, click here.</p>
		</div>
	)
}
